import React, { useContext } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import Swal from 'sweetalert2';
import Logo from "../assets/totm-logo.png"
import { Container } from "reactstrap";
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UserContext } from "../contexts/UserContext"

import {
    USER_TYPE_TITLE,
    HOME_USER_TYPE,
    SCHOOL_USER_TYPE
} from "../utils/defaults"


const MySwal = withReactContent(Swal)

const Login = ( props ) => {
    const {
        loginWithPopup,
    } = useAuth0();

    const user = useContext(UserContext);

    const loginMethod = async ( loginChoice ) => {
        MySwal.close();
        user.setUserType( loginChoice )
        switch(loginChoice) {
            case "school":
                await loginWithPopup()
            break;

            case "home":
                await loginWithPopup({ connection: "ToolsAtHome" })
            break;
            default:
                await loginWithPopup()
        }
    }

    const loginChoices = <div>
        <div className="student-container">
            <span
                className="h6"
                onClick={() => loginMethod("school")}
            >
                {SCHOOL_USER_TYPE} <FontAwesomeIcon icon="school" className="ml-3"/>
            </span>
        </div>
        <div className="student-container">
            <span
                className="h6"
                onClick={() => loginMethod("home")}
            >
                {HOME_USER_TYPE} <FontAwesomeIcon icon="home" className="ml-3"/>
            </span>
        </div>
    </div>

    const handleLogIn = () => {
        MySwal.fire({
            title: `<strong>${USER_TYPE_TITLE}</strong>`,
            icon: 'question',
            html:  loginChoices,
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: false,
        })
    }
  
    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="w-25">
                    <img src={Logo} alt="Tools of the Mind" width="50%"/>
                </div>
            </div>
            <div className="text-center">
                <Container fluid
                    onClick={() => handleLogIn()}
                    className="main-btn center"
                >
                    Login
                </Container>
            </div>
        </div>
    )
};

export default Login;
