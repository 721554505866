// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-control-prev-icon,
.carousel-control-next-icon{
    filter: invert(1);
    width:100%;
}

.visually-hidden{
    display: none;
}

.carousel-control {
    /*size */
    height: 100px;
    width: 100px;

    top: 85%;
    z-index: 250;
    /* position: sticky; */


    /*color */
    background: #ffffff;

    /*shape border*/
    border: 10px solid #fcba03;
    border-radius: 25%; /*zero for square */
}

.visually-hidden{
    display:none;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/carousel.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,QAAQ;IACR,aAAa;IACb,YAAY;;IAEZ,QAAQ;IACR,YAAY;IACZ,sBAAsB;;;IAGtB,SAAS;IACT,mBAAmB;;IAEnB,eAAe;IACf,0BAA0B;IAC1B,kBAAkB,EAAE,mBAAmB;AAC3C;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".carousel-control-prev-icon,\n.carousel-control-next-icon{\n    filter: invert(1);\n    width:100%;\n}\n\n.visually-hidden{\n    display: none;\n}\n\n.carousel-control {\n    /*size */\n    height: 100px;\n    width: 100px;\n\n    top: 85%;\n    z-index: 250;\n    /* position: sticky; */\n\n\n    /*color */\n    background: #ffffff;\n\n    /*shape border*/\n    border: 10px solid #fcba03;\n    border-radius: 25%; /*zero for square */\n}\n\n.visually-hidden{\n    display:none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
