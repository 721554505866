// Core
import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from "react"

// Third Party Tools
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import mergeImages from 'merge-images';
import MicRecorder from 'mic-recorder-to-mp3';
import { filter, isEmpty, head } from "lodash";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

// Design and Style
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player'
import { CarouselItem } from "reactstrap"
import WhiteBackground from "../assets/FFFFFF.png"
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Project Components
import Loading from "../components/Loading";
import NavBar from "../components/NavBar";
import CustomCarousel from "../components/Carousel"
import Board from "../components/Board"

// Project Contexts
import { UserContext } from "../contexts/UserContext"
import { StudentsContext } from "../contexts/StudentsContext"

// Project Utils
import history from "../utils/history";
import { initialLetterSoundListState } from '../utils/defaults'
import { formatSessionData } from "../utils/sessionData";
import { ratio, boardSize, resizeImage } from "../utils/ratios";
import dataURItoBlob from "../utils/uriToBlob";
import {
    PAGE_SIZE,
    REF_WIDTH,
    REF_HEIGHT,
    MIC_REQUIRED_TITLE,
    MIC_REQUIRED_MESSAGE,
    AUDIO_MESSAGES_TITLE,
    AUDIO_MESSAGE_THUMBNAIL_URL
} from "../utils/defaults";

// APIs Methods
import { postDWAFiles } from "../api/external/files"
import { postDWASession } from "../api/external/session"
import { getMessagesForStudent, postMessageAsRead } from "../api/external/students"

// Constant Variables and Inits
const Mp3Recorder = new MicRecorder({ bitRate: 256 });

const initialLevel = {lang: "EN", level: ""}
const boardsIds = [...Array(PAGE_SIZE).keys()];

const Whiteboard = () => {
    const [startTime, setStartTime] = useState(Date.now())

    // eslint-disable-next-line
    const [session, setSession] = useState(uuidv4())

    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    const { userType } = useContext(UserContext)
    const studentsContext = useContext(StudentsContext)

    const initialBoardsData = boardsIds.map(() => ({
        "segments": JSON.stringify({
            "lines": [],
            "width": boardSize.width,
            "height": boardSize.height
        }),
        "imageURL": WhiteBackground,
    }))

    // Session Data
    const [sessionData, setSessionData] = useState()

     // Boards States
    const [color, setColor] = useState("#579869")
    const [boardData, setBoardData] = useState(initialBoardsData);

    const handleBoardData = ( data ) => {
        setBoardData( data )
    }

    const [boardsAnimations, setBoardsAnimation] = useState([
        {
            "height": 0, "timestamp": 0,
            "viewportID": "0", "width": 0,
            "x": 0, "y": 0
        }
    ])

    const handleBoardsAnimations = ( animation ) => {
        let animations = boardsAnimations
        animations.push(animation)
        setBoardsAnimation(animations)
    }
    // Recording States
    const [isRecording, setIsRecording] = useState(false)
    const [audioFile, setAudioFile] = useState()
    const [isBlocked, setIsBlocked] = useState(false)

    // Full Size Image
    const [fullSizeImage, setImage] = useState()
 
    // Levels (Evaluation) States
    const [selectedIndependentLevel, setIL] = useState(initialLevel);
    const [selectedTargetLevel, setTL] = useState(initialLevel);


    // Letters for grades popover
    const [letters, setLetters] = useState(
        initialLetterSoundListState
    )
    
    const [lettersMap, setLettersMap] = useState({
        'consonants': {},
        'long': {},
        'short': {}
    })

    // Recording Functions
    const startRecording = useCallback(() => {
        if (isBlocked) {
            console.log('Permission Denied');
        }
        else {
            Mp3Recorder.start().then(() => {
                setStartTime(Date.now())
                setIsRecording( true );
            }).catch((e) => console.error(e));
        }
    }, [isBlocked])

    const stopRecording = useCallback(() => {
        if (!isRecording)
            return

        Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            const file = new File(
                buffer,
                `${session}.mp3`,
                {
                    type: blob.type,
                    lastModified: Date.now()
                }
            );

            console.log("Saving audio")
            setAudioFile( file )
            setIsRecording( false )
        }).catch((e) => {
            console.log(e);
        });
    }, [isRecording, session])

    // @Home messages
    const handleOnAudioMessageEnd = useCallback(( studentID, audioID  ) => {
        const markMessage = async (student, audio) => {
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })
                await postMessageAsRead(student, audio, accessToken)
            } catch (error) {
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })
                await postMessageAsRead(studentID, audioID, accessToken)
            }
        }

        markMessage(studentID, audioID)
    }, [getAccessTokenSilently, getAccessTokenWithPopup])


    /**
     * Main Use Effect
     *   1. Get JWT Token for Access Requests
     *       Get Students
     *     Post Session Data
     *   2. Check Users Permissions to record and Microphone use
     */

    
    useEffect(() => {
        // Get Audio messages for a Student
        const getAudioMessages = async ( student ) => {
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const data = await getMessagesForStudent(student, accessToken)
                return data
                
            } catch (error) {
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })
        
                const data = await getMessagesForStudent(student, accessToken)
                return data
            }
        }

        // User Microphone Permission
        try {
            navigator.permissions.query({name:'microphone'})
                .then((result) => {
                    if (result.state === 'prompt' || result.state === 'denied') {
                        Swal.queue(
                            [{
                                title: MIC_REQUIRED_TITLE,
                                text: MIC_REQUIRED_MESSAGE,
                                icon: 'warning'
                            }]
                        )
                    }
                });
        } catch (error) {
            Swal.queue(
                [{
                    title: MIC_REQUIRED_TITLE,
                    text: MIC_REQUIRED_MESSAGE,
                    icon: 'warning'
                }]
            )
        }

        // Recording Status
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                console.log('Permission Granted');
                setStartTime(Date.now());
                startRecording();
            })
            .then(() => {
                 // @Home - Student Audio messages
                if ( userType === "home") {
                    getAudioMessages(studentsContext.selectedStudent.ID)
                        .then(( audioMessages ) => {
                            if ( audioMessages.length > 0 ) {
                                let newSwal = withReactContent(Swal)
                                newSwal.mixin({
                                    title: AUDIO_MESSAGES_TITLE( audioMessages.length ),
                                    showCancelButton: true,
                                    showConfirmButton: true
                                }).queue(audioMessages.map(audio => {
                                    return {
                                        
                                        html: 
                                        <div>
                                            <img
                                                src={AUDIO_MESSAGE_THUMBNAIL_URL}
                                                width="80%"
                                                height="260px"
                                                alt="New Audio message."
                                            />
                                            <ReactPlayer
                                                url={ audio.audio_url }
                                                controls={true}
                                                width="100%"
                                                height="60px"
                                                onEnded={() => {
                                                    handleOnAudioMessageEnd(
                                                        studentsContext.selectedStudent.ID,
                                                        audio.ID
                                                    )
                                                }}
                                            />
                                        </div>,
                                        confirmButtonText: <span>Continue <FontAwesomeIcon icon="arrow-right" /></span>,
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        footer: <span><strong>Sent at:</strong> { moment(audio.timestamp).format("dddd, MMM Do YYYY - h:mm A")}</span>
                                    }
                                }))
                            }
                        })
                }
            })
            .catch(() => {
                console.warn('Permission Denied');
                setIsBlocked(true)
                Swal.queue(
                    [{
                        title: MIC_REQUIRED_TITLE,
                        text: MIC_REQUIRED_MESSAGE,
                        icon: 'warning'
                    }]
                )
            })
        
        
    // eslint-disable-next-line
    }, [
        handleOnAudioMessageEnd, startRecording,
        getAccessTokenSilently, getAccessTokenWithPopup,
        userType
    ])


    /**
     * Final UseEffect to Post Session Data and Audio Files and both
     * First Image and Full Size Image
     */

    useEffect(() => {
        if (isEmpty(sessionData)){
            console.log("empty")
            
            return
        }
        const postSession = async ( data ) => {
            // console.log("post session activated data >>", data)
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })
        
                await postDWASession(data, accessToken)
            } catch (error) {
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })
        
                await postDWASession(data, accessToken)
            }
            
        }

        const postFile = async ( file, contentType ) => {
            // console.log("post file activated", {"file":file, "content-type":contentType})
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })

        
                await postDWAFiles(
                    file,
                    contentType,
                    accessToken
                )
            } catch (error) {
                // console.log("error in post file", error)
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })
        
                await postDWAFiles(
                    file,
                    contentType,
                    accessToken
                )
            }
        }

        if (!isBlocked && !isRecording && audioFile && fullSizeImage) {
        
            const thumbnail = new File(
                [head(sessionData.imageBlobs)],
                `${session}.jpg`
            )
            
            const full_size = new File(
                [fullSizeImage],
                `${session}_fullsize.jpg`
            )
            
            Promise.allSettled([
                postSession(sessionData.sessionData),
                postFile(thumbnail, "image/jpeg"),
                postFile(full_size, "image/jpeg"),
                postFile(audioFile, "audio/mpeg")
            ]).then( values => {
                Swal.isVisible() && Swal.close()

                history.push("/start")
                studentsContext.resetSelectedStudent()
                studentsContext.resetTeacher()
            })
        }
    }, [
        session, sessionData, isBlocked,
        isRecording, audioFile,
        fullSizeImage, studentsContext,
        getAccessTokenSilently,
        getAccessTokenWithPopup
    ])

    const gatherSessionData = async () => {
        Swal.queue([{
            title: 'Please Wait!',
            text: 'Processing Session.',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        }]);

        const end = Date.now();
        stopRecording();

        // Base Data
        const sessionStartStr = moment(new Date(startTime)).format()

        const sessionEndStr = moment(new Date(end)).format()
        const childID = studentsContext.selectedStudent.ID

        const duration = ( end - startTime ) / 1000
        
        const independentLanguage = selectedIndependentLevel.lang
        const independentLevel = selectedIndependentLevel.level
        
        const targetLanguage = selectedTargetLevel.lang
        const targetLevel = selectedTargetLevel.level

        const drawing = {
            "shapes": boardData,
            "viewportAnimations": boardsAnimations,
            "ratio": ratio
        }

        let imageData = filter(boardData, (o) => o.imageURL).map((o, i) => {
            return {
                src: o.imageURL,
                x: boardSize.width * i,
                y: 0
            }
        })

        // Merge all PAGE_SIZE images into one and then resize it to
        // PAGE_SIZE * REF_WIDTH default size.
        mergeImages(imageData, {width: boardSize.width * imageData.length, height: boardSize.height})
            .then( b64 => {
                resizeImage(
                    b64,
                    REF_WIDTH * PAGE_SIZE,
                    REF_HEIGHT,
                    "image/jpeg",
                    ( imageULR ) => {
                        setImage( dataURItoBlob(imageULR) )
                    }
                )
            })
            

        const data = {
            childID: childID,
            duration: duration,
            endTime: sessionEndStr,
            independentLanguage: independentLanguage,
            independentLevel: independentLevel,
            sessionEndStr: sessionEndStr,
            sessionID: session,
            sessionStartStr: sessionStartStr,
            startTime: sessionStartStr,
            targetLanguage: targetLanguage,
            targetLevel: targetLevel,
            teacherID: studentsContext.teacher,
            letterSounds: letters
        }

        const sData = formatSessionData(
            startTime,
            data,
            drawing,
        )


        setSessionData( sData )


    }

    const handleSelectStudent = ( student ) => {
        studentsContext.setStudent(student);
    }
    
    return (
        <div
            id="start"
            className="d-flex flex-column h-100"
            style={{maxWidth:'100vw'}}
        >
            <NavBar
                isRecording={ isRecording }
                setColor={ setColor }
                closeSession={ gatherSessionData }
                targetLanguage={ selectedTargetLevel.lang }
                independentLevel={ selectedIndependentLevel }
                targetLevel={ selectedTargetLevel }
                setIL={ setIL }
                setTL={ setTL }
                sessionID={session}
                student={ studentsContext.selectedStudent }
                students={ studentsContext.students }
                teacher={ studentsContext.teacher }
                setSelectedStudent={ handleSelectStudent }
                letters={letters}
                lettersMap={lettersMap}
                setLettersMap={setLettersMap}
                setLetters={setLetters}
                submitSession={ gatherSessionData }
                
            />
            <div className="whiteboard">
                <CustomCarousel
                    setBoardsAnimation={ handleBoardsAnimations }
                    start={ startTime }
                >
                    {
                        boardsIds.map(( index ) => (
                            <CarouselItem
                                key={`carousel-item-${index}`}
                                unmountOnExit={ true }
                            >
                                <Board
                                    key={`board-${index}`}
                                    index={ index }
                                    data={ boardData }
                                    setData={ handleBoardData }
                                    color={ color }
                                    ratio={ ratio }
                                />
                            </CarouselItem>
                            )
                        )
                    }
                </CustomCarousel>
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Whiteboard, {
    onRedirecting: () => <Loading />
});