import React from "react";
import loading from "../assets/loading.svg";

import { useAuth0 } from "@auth0/auth0-react";

import history from "../utils/history"


const Loading = ( props ) => {
  const { error } = useAuth0();

  if (error) {
    history.push("/")
  }

  return (
    <div className="spinner" style={props.style}>
      <img src={loading} alt="Loading" />
    </div>
  )
};

export default Loading;
