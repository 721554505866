// Core
import React, {
    useEffect,
    useContext
} from "react";

// Third Party Tool
import { head } from "lodash"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

// Design and Style
import Logo from "../assets/totm-logo.png"
import "../assets/styles.css"
import Swal from 'sweetalert2'
import { Container } from "reactstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import withReactContent from 'sweetalert2-react-content'

// Project Components
import Footer from "../components/Footer";
import Loading from "../components/Loading";

// Project Contexts
import { UserContext } from "../contexts/UserContext"
import { StudentsContext } from "../contexts/StudentsContext"

// Project Utils
import history from "../utils/history";

// API Methods
import { getStudents }  from "../api/external/students"
import { GALLERY_SELECT_STUDENT, LOADING_STUDENT_MESSAGE, LOADING_STUDENT_TITLE } from "../utils/defaults";

const MySwal = withReactContent(Swal)

const Start = () => {

    const {
        getAccessTokenSilently,
        getAccessTokenWithPopup
    } = useAuth0();

    const { userType } = useContext(UserContext)
    const { students, setStudents, setTeacher, setStudent } = useContext(StudentsContext)

    useEffect(() => {
        if ( students.length ) { return }

        Swal.fire({
            title: LOADING_STUDENT_TITLE,
            text: LOADING_STUDENT_MESSAGE,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        });

         const getStudentsData = async ( token ) => {
            const data = await getStudents(token)
            setStudents(data.students)

            if (userType === "school") {
                setTeacher(data.teacher)
            }
            Swal.close()
         }

        // JWT Token Request
         const getStudentsDataWithToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })
                await getStudentsData(accessToken)
            } catch (error) {
                // console.log("error", error)
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })
                await getStudentsData(accessToken)
            }
        }
        
        getStudentsDataWithToken();
    }, [getAccessTokenSilently, getAccessTokenWithPopup, setStudents, setTeacher, students.length, userType])

    const handleStudentSelectForGallery = (student) => {
        MySwal.isVisible() && MySwal.close()
        setStudent( student )
        history.push(`/sessions-gallery/${student.ID}`)
    }

    const handleStudentSelectForStart = (student) => {
        MySwal.isVisible() && MySwal.close()
        setStudent(student)
        setTeacher(student.teacherID)
        history.push("/whiteboard");
    }

    let studentListComponentGallery = <InfiniteScroll
        dataLength={students.length}
        hasMore={false}
        loader={<h4>Loading...</h4>}
    >
    {
        students.map((student, index) => (
            <div
                className="student-container"
                key={`student-${index}`}
                onClick={() => handleStudentSelectForGallery(student) }
            >
                <span
                    className="h6"
                >
                    {student.display_name}
                </span>
            </div>
        ))
    }
    </InfiniteScroll>

    let studentListComponentStart = <InfiniteScroll
        dataLength={students.length}
        hasMore={false}
        loader={<h4>Loading...</h4>}
    >
        {
        students.map((student, index) => (
            <div
                className="student-container"
                key={`student-${index}`}
                onClick={() => handleStudentSelectForStart(student) }
            >
                <span
                    className="h6"
                >
                    {student.display_name}
                </span>
            </div>
        ))
        }
    </InfiniteScroll>

    const handleOnGallery = () => {
        if (students.length === 1 ){
            handleStudentSelectForGallery(
                head(students)
            )
            return
        }

        MySwal.queue([{
            title: <strong>{ GALLERY_SELECT_STUDENT }</strong>,
            icon: 'info',
            html:  studentListComponentGallery,
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: false,
        }])
    }

    const handleOnStart = () => {
        switch(userType){
            case "home":
                if (students.length === 1 ){
                    handleStudentSelectForStart(
                        head(students)
                    )
                    return
                }

                MySwal.fire({
                    title: '<strong>Select a Student</strong>',
                    icon: 'info',
                    html:  studentListComponentStart,
                    allowOutsideClick: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                })
                break;
            case "school":
                history.push("/whiteboard");
                break;
            default:
                history.push("/whiteboard");
        }
    }

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="w-25">
                    <img src={Logo} alt="Tools of the Mind" width="50%"/>
                </div>
                
                {
                    students.length > 0 ?
                    <div
                        onClick={() => handleOnGallery()}
                        className="gallery-btn h-25 align-self-center text-center"
                    >
                        <span><strong>View Gallery</strong></span>
                    </div>
                    :
                    ''
                }
                
            </div>
            <div className="text-center">
                <Container fluid
                    onClick={() => handleOnStart()}
                    className="main-btn center"
                >
                    <span><strong>Start</strong></span>
                </Container>
                <Footer />
            </div>
        </div>
    )
}

export default withAuthenticationRequired(Start, {
    onRedirecting: () => <Loading />
});